@import '~bootstrap/scss/bootstrap';
@import './css/_colors';

html,
body,
main {
  margin: 0;
  height: 100%;
}

body,
button,
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'BCSans', 'Noto Sans', Arial, 'sans serif';
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[disabled] {
  color: hsl(0, 0%, 50%);
  background-color: hsl(0, 0%, 95%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin: 0.5em 0 0.5em 0;
}

h1 {
  font-size: 2em;
  text-transform: uppercase;
  margin-top: 0;
}

h2 {
  font-size: 1.25em;
  text-transform: uppercase;
  margin-top: 0;
}

h3 {
  font-size: 1em;
  text-transform: uppercase;
  margin-top: 0;
}

h4 {
  font-size: 0.75em;
  text-transform: uppercase;
  margin-top: 0;
}

.caps {
  text-transform: uppercase;
}

.forceFlexRow {
  flex-basis: 100%;
  height: 0;
}

.fvh {
  height: 100%;
}

#root {
  height: 100%;

  &>div:first-of-type {
    height: 100%;
  }
}

// *************************************************
// Button Customization
// *************************************************
.btn.btn-link {
  cursor: pointer;
  color: $action-button-color;

  &.red {
    color: $danger-color;
  }

  &:hover {
    filter: brightness(50%);
  }
}

// *************************************************
// Tooltip Customization
// *************************************************
.type-light.place-top {
  padding: 2px 5px 2px 5px;
  border: solid 1px $primary-color;

  &:after {
    border-top: 5px solid $primary-color !important;
  }
}

.type-light.place-left {
  padding: 2px 5px 2px 5px;
  border: solid 1px $primary-color;

  &:after {
    border-left: 5px solid $primary-color !important;
  }
}

.type-light.place-right {
  padding: 2px 5px 2px 5px;
  border: solid 1px $primary-color;

  &:after {
    border-right: 5px solid $primary-color !important;
  }
}

.type-light.place-bottom {
  padding: 2px 5px 2px 5px;
  border: solid 1px $primary-color;

  &:after {
    border-bottom: 5px solid $primary-color !important;
  }
}

// *************************************************
// Forms
// *************************************************
.frm-in {
  padding-bottom: 0.5em;

  label {
    font-weight: bold;
  }

  &.chk {
    padding-bottom: 0em;

    label {
      cursor: pointer;
    }
  }

  &.chg {
    padding-bottom: 0;

    label:not(:first-child) {
      display: inline;
      font-weight: normal;
    }
  }

  &.rag {
    label:not(:first-child) {
      display: inline;
      font-weight: normal;
    }
  }

  input[type="number"] {
    text-align: right;
  }
}

// *************************************************
// Modal
// *************************************************
.modal,
.modal-full {
  .modal-quill {
    flex: 1;

    .ql-editor {
      resize: both;
      // TODO: Figure out flexbox implementation.
      // I can't figure out how to make this expand.
      width: calc(100vw - 7vw);
      height: calc(100vh - 20em);
    }
  }
}

// *************************************************
// Tooltip
// *************************************************
#main-tooltip {
  height: unset !important;
  z-index: 999;
}

#main-tooltip-right {
  height: unset !important;
  z-index: 999;
}

// *************************************************
// Status
// *************************************************
.status-draft {
  color: $status-draft;
}

.status-publish {
  color: $status-publish;
}

.status-published {
  color: $status-published;
}

.status-unpublish {
  color: $status-unpublish;
}

.status-unpublished {
  color: $status-unpublished;
}