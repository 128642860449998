// Links
$link-primary-color: #646293;
$link-primary-hover-color: #565a8b;
$link-primary-active-color: #b3a5d3;
$link-gray-color: #9f9196;

// Lines
$line-primary-color: #003366;

// Tables
$table-odd-row: unset;
$table-even-row: #EDEDED;
$table-hover-row: #EDEDED;

// Icon
$icon-primary-color: #003366;
$icon-primary-hover-color: #2e4c69;

// ***************************************
// DO NOT USE ANY OF THESE BELOW THIS LINE
// ***************************************

$action-button-color: #007af5;
$background-color: white;
$primary-color: #003366;
$primary-color-rgb: 0,
51,
102;
$primary-light-color: #38598a;
$primary-light-color-rgb: 56,
89,
138;
$secondary-variant-color: #428bca;
$secondary-variant-color-rgb: 66,
139,
202;
$light-variant-color: #606060;
$dark-variant-color: #121212;
$text-color: #494949;
$accent-color: #fcba19;
$active-tab-color: #fdd672;
$light-accent-color: #fcd573;
$light-label-color: #9295a0;
$submitted-color: #172b4d;
$danger-color: #d8292f;
$light-danger-color: #fae1e7;
$icon-light-color: #3b99fc;
$active-color: #007bff;
$accepted-color: #ead624;
$completed-color: #2e8540;
$cancelled-color: #9295a0;
$filter-background-color: #f2f2f2;
$slide-out-blue: #1a5a96;
$selected-color: #dff0d8;
$border-color: #efefef;
$action-icon-color: #00478f;
$darker-background-color: #faf9f7;

$production-background-color: #003366;
$development-background-color: #b51963;
$test-background-color: #5ba300;

// Table
$table-color: #dee2e6;
$table-header-color: #e9ecef;
$table-even-row-color: #e9ecef;
$table-odd-row-color: white;
$table-hover-even-row-color: #e0e0e0;
$table-hover-odd-row-color: #ededed;

// Subscriber defaults
$beige-background-color: #efebdf;
$dark-header-color: #363434;
$item-active-color: #24b6d4;
$menu-item-color: #43425d;
$selected-menu-item-color: #3c3b54;
$light-inactive-button: #ddd6c8;
$sticky-note-color: #fff2cb;
$light-gray: #f9f9f9;
$light-blue: #56c7de;

// Form
$form-background-color: white;
$dropdown-background-color: #32507c;
$input-required-border-color: #428bca;

// Status
$status-draft: #9295a0;
$status-publish: #0b7200;
$status-published: #0b7200;
$status-unpublish: #d8292f;
$status-unpublished: #d8292f;

// Event of the Day - Event Type
$eotd-event-type-issues: #bb1111;
$eotd-event-type-proactive: #006600;

// Bootstrap defaults
$bs-text-opacity: 1;
$bs-blue: #0d6efd;
$bs-indigo: #6610f2;
$bs-purple: #6f42c1;
$bs-pink: #d63384;
$bs-red: #dc3545;
$bs-orange: #fd7e14;
$bs-yellow: #ffc107;
$bs-green: #198754;
$bs-teal: #20c997;
$bs-cyan: #0dcaf0;
$bs-white: #fff;
$bs-gray: #6c757d;
$bs-gray-dark: #343a40;
$bs-gray-100: #f8f9fa;
$bs-gray-200: #e9ecef;
$bs-gray-300: #dee2e6;
$bs-gray-400: #ced4da;
$bs-gray-500: #adb5bd;
$bs-gray-600: #6c757d;
$bs-gray-700: #495057;
$bs-gray-800: #343a40;
$bs-gray-900: #212529;
$bs-primary: #0d6efd;
$bs-secondary: #6c757d;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-warning: #ffc107;
$bs-danger: #dc3545;
$bs-light: #f8f9fa;
$bs-dark: #212529;
$bs-primary-rgb: 13,
110,
253;
$bs-secondary-rgb: 108,
117,
125;
$bs-success-rgb: 25,
135,
84;
$bs-info-rgb: 13,
202,
240;
$bs-warning-rgb: 255,
193,
7;
$bs-danger-rgb: 220,
53,
69;
$bs-light-rgb: 248,
249,
250;
$bs-dark-rgb: 33,
37,
41;
$bs-white-rgb: 255,
255,
255;
$bs-black-rgb: 0,
0,
0;
$bs-body-color-rgb: 33,
37,
41;
$bs-body-bg-rgb: 255,
255,
255;